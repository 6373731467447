import { Link } from 'gatsby';

import React from 'react';

import icon_play from '../../images/icon-play-alt.svg';

// TODO: This is extracted from utils right now. Because the `getEpisodeThumbnail` func is used in multiple places.
// Once refactor is done, move this to utils.
export const getAboutUsEpisodeThumbnail = (episode, quality = 'mqdefault') => {
  let thumbnailUrl = '';
  const youtubeIds = episode?.youtubeId;

  if (youtubeIds.length) {
    thumbnailUrl = youtubeIds[0].text;

    if (thumbnailUrl.includes('youtube.com')) {
      thumbnailUrl = thumbnailUrl.split('v=')[1];
      const ampersandPosition = thumbnailUrl.indexOf('&');
      if (ampersandPosition !== -1) {
        thumbnailUrl = thumbnailUrl.substring(0, ampersandPosition);
      }
    }
    thumbnailUrl = `https://img.youtube.com/vi/${thumbnailUrl}/${quality}.jpg`;
  }
  return thumbnailUrl;
};

export default function TestimonialMediaItem({ row, testimonial }) {
  const aboutUsThumbnailImage = getAboutUsEpisodeThumbnail(
    testimonial.data,
    row === 'row1' ? 'sddefault' : 'mqdefault'
  );

  const dataTitle = testimonial?.data?.title[0]?.text;
  const dataVideoDuration = testimonial?.data?.videoDuration[0]?.text;
  const dataSlugs = testimonial?.slugs[0];

  const removeExtraHyphen = (dataSlugs) => {
    return dataSlugs.replace('--', '-');
  };
  const videoLinkURL = `/video_episodes/${removeExtraHyphen(dataSlugs)}`;

  return (
    <Link to={videoLinkURL} className={`media ${row === 'row1' ? 'ratio_4-3' : 'ratio_16-9'}`}>
      <div className="m_overlay" />
      <div
        className="m_overlay-image bgImage"
        style={{
          backgroundImage: aboutUsThumbnailImage ? `url(${aboutUsThumbnailImage})` : 'transparent',
        }}
      />

      <div className="media-content">
        <h2 className="media-title">{dataTitle}</h2>
        <div className="btn btn-watch-play btn-transparent">
          <img src={icon_play} alt="Icon Play" className="icon-play-alt" />
          <span>Watch</span>
        </div>
      </div>

      {dataVideoDuration && (
        <div className="m_run-time m_run-time-outer">
          <span>{dataVideoDuration}</span>
        </div>
      )}
      <div className="m_border" />
    </Link>
  );
}
