import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import renderHtml from 'htmr';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import iconInstagramAlt from '../images/icon-instagram-alt.svg';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import TestimonialMediaItem from '../components/Testimonial/TestimonialMediaItem';

const AboutPage = (props) => {
  const {
    data: { bgAbout, allPrismicAbout, allPrismicEpisodes },
  } = props;
  const allPrismicData = allPrismicAbout?.nodes[0]?.data;
  const [wpData, setWpData] = useState(allPrismicData);
  const [videos, setVideos] = useState(allPrismicEpisodes.nodes);

  const dataPageTitle = wpData?.pageTitle?.text;
  const dataPageSubtitle = wpData?.pageSubtitle?.text;
  const dataPageDescription = wpData?.desctiption1?.text;
  const dataDescription1 = wpData?.desctiption1?.text;
  const dataDescriptionImg1 = wpData?.descriptionImage1;
  const dataDescription2 = wpData?.description2?.text;
  const dataDescriptionImg2 = wpData?.descriptionImage2;
  const dataDescription3 = wpData?.description3?.text;
  const pageDescriptions = wpData.pageDescription.text;

  let vidTitle;
  const videoDataTitle = videos[0]?.data?.title[0]?.text;
  const isVideoLengthOdd = videos?.length % 2 === 1;
  const slicedVideos = isVideoLengthOdd ? videos.slice(1, 3) : videos.slice(0, 2);

  return (
    <BodyClassName className="body-light page-about">
      <DefaultLayoutComponent title="About">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={bgAbout.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm">
              <div className="pd-x-sm pb-container">
                <h3 className="pb-title">{dataPageTitle}</h3>
              </div>
            </div>
          </BackgroundImage>
          <section className="about-container-top over_lpd-container pd-0">
            <div className="section about-contents bg-transparent pd-t-0">
              <div className="wrapper wrapper-md wrapper-text pd-x-md">
                <div className="abt_text intro">
                  <h4 className="abt_title">{dataPageSubtitle}</h4>
                  <div className="abt_content">
                    <p>{renderHtml(pageDescriptions)}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="about-container-bottom pd-y-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section about-contents">
                <div className="wrapper wrapper-md pd-x-md">
                  <div className="abt_text">
                    <h4 className="abt_title">Our Story</h4>
                    <div className="abt_content">
                      <p>{renderHtml(dataDescription1)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section about-image pd-t-0">
                <div className="wrapper wrapper-lg pd-x-md">
                  {dataDescriptionImg1?.fluid?.src && (
                    <LazyLoadImage
                      src={dataDescriptionImg1.fluid.src}
                      alt={dataDescriptionImg1.alt}
                      wrapperClassName="image-wrapper"
                      effect="blur"
                    />
                  )}
                </div>
              </div>
              <div className="section about-contents pd-t-0">
                <div className="wrapper wrapper-md pd-x-md">
                  <div className="abt_text">
                    <div className="abt_content">
                      <p>{renderHtml(dataDescription2)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section about-image pd-t-0">
                <div className="wrapper wrapper-lg pd-x-md">
                  {dataDescriptionImg2?.fluid?.src && (
                    <LazyLoadImage
                      src={dataDescriptionImg2.fluid.src}
                      alt={dataDescriptionImg2.alt}
                      wrapperClassName="image-wrapper"
                      effect="blur"
                    />
                  )}
                </div>
              </div>
              <div className="section about-contents pd-t-0">
                <div className="wrapper wrapper-md pd-x-md">
                  <div className="abt_text">
                    <div className="abt_content">
                      <p>{renderHtml(dataDescription3)}</p>
                    </div>
                  </div>
                </div>
              </div>
              {videos?.length && (
                <div className="section about-contents pd-t-0">
                  <div className="wrapper wrapper-lg pd-x-sm">
                    <div className="layout-media layout-media-watch">
                      <div className="layout-inner layout-2 d-flex">
                        {isVideoLengthOdd && (
                          <div className="cols item-1 cols-1" key={videoDataTitle}>
                            <TestimonialMediaItem testimonial={videos[0]} />
                          </div>
                        )}
                      </div>
                      <div className="layout-inner layout-2 d-flex">
                        {isVideoLengthOdd
                          ? slicedVideos.map((vid) => {
                              vidTitle = vid?.data?.title[0]?.text;
                              return (
                                <div className="cols item-1 cols-1" key={vidTitle}>
                                  <TestimonialMediaItem row="row1" testimonial={vid} />
                                </div>
                              );
                            })
                          : slicedVideos.map((vid) => {
                              vidTitle = vid?.data?.title[0]?.text;
                              return (
                                <div className="cols item-1 cols-1" key={vidTitle}>
                                  <TestimonialMediaItem row="row1" testimonial={vid} />
                                </div>
                              );
                            })}
                      </div>
                      <div className="layout-inner layout-2x2 d-flex">
                        <div className="cols item-2 d-flex">
                          {videos.length % 2 === 1
                            ? videos.slice(3).map((vid) => {
                                vidTitle = vid?.data?.title[0]?.text;
                                return (
                                  <div className="cols-inner cols-inner-1" key={videoDataTitle}>
                                    <TestimonialMediaItem row="row2" testimonial={vid} />
                                  </div>
                                );
                              })
                            : videos.slice(2).map((vid) => {
                                vidTitle = vid?.data?.title[0]?.text;
                                return (
                                  <div className="cols-inner cols-inner-1" key={vidTitle}>
                                    <TestimonialMediaItem row="row2" testimonial={vid} />
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="section review-website">
                <div className="wrapper wrapper-md pd-x-md">
                  <div className="explore-link mg-t-0">
                    <a
                      href="https://www.adventistreview.org/reaching-the-secular-mind-the-lineage-journey"
                      className="btn-r-w"
                      target="_blank"
                    >
                      <span>Read our write up on the adventist review website</span>
                    </a>
                  </div>
                </div>
              </div>
              <section className="index-social">
                <h3 className="sectionHeading pd-x-md">
                  Follow Us On
                  <span>
                    <img src={iconInstagramAlt} alt="Instagram" className="icon-insta" />
                    Instagram
                  </span>
                </h3>
                <div className="insta-gallery">
                  <div className="elfsight-app-58f89894-78f0-48a8-bb54-4529326bc8b2"></div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgAbout: file(relativePath: { eq: "bg-about.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allPrismicEpisodes(
      filter: { data: { categories: { elemMatch: { episodes_category: { slug: { eq: "about-page-videos" } } } } } }
    ) {
      nodes {
        data {
          title {
            text
          }
          videoDuration: video_duration {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        id
        url
        slugs
      }
    }

    allPrismicAbout {
      nodes {
        data {
          pageTitle: page_title {
            text
          }
          pageSubtitle: page_subtitle {
            text
          }
          pageDescription: page_description {
            text
          }
          desctiption1 {
            text
          }
          descriptionImage1: description_image1 {
            url
            alt
            fluid {
              src
            }
          }
          description2 {
            text
          }
          descriptionImage2: description_image2 {
            alt
            url
            fluid {
              src
            }
          }
          description3 {
            text
          }
        }
      }
    }
  }
`;

export default AboutPage;
